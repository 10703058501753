import React from "react"
//import backIcon from '../assets/icons/back.svg'
// gatsby link works better than @reach/router when linking from pages outside /app.
import { Link, navigate } from 'gatsby'

export function NextButton(props) {

    const link = props.root ? props.target : '/app' + props.target

    const nextStyle = {
        width: props.width ? props.width : "50%"
    }

    return(
        <div className="button" style = {nextStyle}>
            {props.disabled ? 
                <button className="button-inner"
                    disabled>
                    {props.text}
                </button> :
            <Link to={link}>
                <button className="button-inner"
                    onClick = {props.onClick ? props.onClick : () => {}}
                >
                    {props.text}
                </button>
            </Link>}
        </div> 
    )
}

export function BackButton(props) {

    return(
        <div className="button">
            {props.disabled ? 
                <button className="button-inner"
                    disabled>
                    {props.text}
                </button> :
                <button className="button-inner"
                    onClick={() => navigate(-1)}
                >
                    {props.text}
                </button>
            }
        </div> 
    )
}