import React from "react"
import { BackButton } from '../components/Icons'

const Page = () => {
    
    const bodyStyle = {margin: 'auto', width: '75%', textAlign: 'left', overflowY: 'auto', maxHeight: '70%'}

    return(
        <div className="base-layout">
            <div className="title">
                privacy policy
            </div>
            <div className="medium-text" style = {bodyStyle} >
                This policy was last updated on 4 June 2022.
                <br/><br/>
                This website is run by Diarmid Mackenzie, who can be contacted through the <a href='https://discord.gg/wHrU2afRFn'>decision.ninja discord server</a>
                <br/><br/>
                This website does not collect any personal data, or use any cookies.
                <br/><br/>
                While using this website, data that you enter will be stored on this device.  This data will persist on your device until the browser window used to access this website is closed.
                <br/><br/>
                This website also facilitates longer term storage and sharing of personal information entered as follows:
                <ul>
                    <li>Copying data to your device's clipboard, where it will be stored until it is deleted from the device's clipboard</li>
                    <li>Sharing data on Social Media (Twitter or Facebook).</li>
                </ul>
                Data shared on 3rd party services will be subject to the privacy and data policies of those services.
                <br/><br/>
                If you participate in the <a href='https://discord.gg/wHrU2afRFn'>decision.ninja discord community</a>, data that you share within the community (including the content that you contribute) is governed by Discord's <a href="https://discord.com/privacy">privacy policy</a>.
            </div>
            <BackButton
                text="back"/>
        </div>
    )
}

export default Page